import React from 'react'
import { employes } from 'images'
import {
  direct,
  hand,
  kosten,
  vragen,
  steps,
  one,
  two,
  three,
  four,
  screenshot,
  confetti,
} from 'images/product/salaris'
import * as images from 'images/home'
import { window } from 'browser-monads'
import { Link, withPrefix } from 'gatsby'
import Layout from '../../../layouts'
import Helmet from 'react-helmet'
import {
  avy_1,
  avy_2,
  avy_3,
  avy_5,
  avy_logo,
  woodwatch_1,
  woodwatch_2,
  woodwatch_3,
} from 'images/klanten'

import config from '../../../config'
const { appUrl, signupRoute } = config

const {
  avatars: { marieke, peter },
} = images

import FeatureCard from 'components/feature-card'
import Checkmark from 'components/misc/checkmark'
import Crossmark from 'components/misc/crossmark'
import ClientCard from 'components/client-card'
import ClientCardContainer from 'components/client-card-container'
import Steps from 'components/steps'

const RowOne = [
  'Geen opstartkosten',
  'Onbeperkt wijzigingen doen',
  'Mobiele app voor werknemers',
  'Vakantiedagen en vakantiegeld',
  'Jaaropgaven voor alle werknemers',
  'Loonaangifte bij de Belastingdienst',
  'Aangifte bij het pensioenfonds',
  'Toeslagen en vergoedingen',
  'Ondersteuning voor de werkkostenregeling',
  'Journaalposten',
  'Uitgebreide rapportages',
]

const RowTwo = [
  'Loonaangifte bij de Belastingdienst',
  'Aangifte bij het pensioenfonds',
  'Toeslagen en vergoedingen',
  'Ondersteuning voor de werkkostenregeling',
  'Journaalposten',
  'Uitgebreide rapportages',
  'Geen opstartkosten',
  'Onbeperkt wijzigingen doen',
  'Mobiele app voor werknemers',
  'Vakantiedagen en vakantiegeld',
  'Jaaropgaven voor alle werknemers',
]

const otherClientStories = [
  {
    name: 'Ilse van Nierop',
    functionTitle: 'Finance & Operations, Avy',
    companyLogo: avy_logo,
    description:
      'Ik kan zelf de uren invullen, een medewerker aanmaken en wijzigen doorvoeren zonder hiervoor iemand te hoeven inschakelen. Dat is echt heel fijn!',
    portraitImage: avy_5,
    portraitImageSmall: avy_1,
    internallink: true,
    link: '/klanten/avy/',
    btnText: 'Lees ervaring&nbsp;→',
  },
  {
    name: 'Kevin van der Veer',
    functionTitle: 'Mede-oprichter, WoodWatch',
    companyLogo: woodwatch_2,
    description: 'Payroll, dat kost me gewoon teveel.',
    portraitImage: woodwatch_2,
    portraitImageSmall: woodwatch_2,
    quote: 'Payroll, dat kost me gewoon teveel.',
    internallink: true,
    link: '/klanten/woodwatch/',
    btnText: 'Lees ervaring&nbsp;→',
  },
  {
    name: 'Johan',
    functionTitle: 'HR Manager, airbnb',
    companyLogo: 'https://via.placeholder.com/102x32/3E4F68/fff',
    description:
      'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x506',
    portraitImageSmall: 'https://via.placeholder.com/344x224',
    quote: 'Het werkt als een trein',
  },
]

const allPlans = [
  {
    problem: 'Geen ingewikkeld en moeilijk salarispakket',
    solution: 'Gebruiksvriendelijk systeem, speciaal voor het MKB',
  },
  {
    problem: 'Geen handmatig e-mailverkeer',
    solution: 'Zelf direct de verloning doen',
  },
  {
    problem: 'Geen fouten in je salarisverwerking',
    solution: 'Zelf de verloning bekijken en aanpassen',
  },
  {
    problem: 'Geen salariskennis nodig',
    solution: 'Makkelijke software die je door alle stappen leidt',
  },
  {
    problem: 'Geen boetes van de belastingdienst',
    solution: 'Automatische herinneringen',
  },
]

class SalarisAdmin extends React.Component {
  constructor(props) {
    super(props)

    this.scrolling = this.scrolling.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrolling)
    this.scrolling()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrolling)
  }

  scrolling() {
    if (window.innerWidth > 1280) {
      const steps = this.refs.steps
      const stepSizes = steps.getBoundingClientRect()
      const windowHeight = window.innerHeight

      if (windowHeight - stepSizes.height >= stepSizes.top) {
        steps.classList.add('start')
      }

      const slider = this.refs.slider
      const sliderTop = Math.round(slider.getBoundingClientRect().top)

      const firstStep = this.refs.stepOne
      const firstStepTop = Math.round(firstStep.getBoundingClientRect().top)

      const secondStep = this.refs.stepTwo
      const secondStepTop = Math.round(secondStep.getBoundingClientRect().top)

      const thirdStep = this.refs.stepThree
      const thirdStepTop = Math.round(thirdStep.getBoundingClientRect().top)

      const finalStep = this.refs.stepFour
      const { top, height } = finalStep.getBoundingClientRect()
      const full = Math.round(top)

      if (secondStepTop <= 540 && secondStepTop > 0) {
        this.refs.illuOne.classList.remove('show')
        this.refs.illuTwo.classList.add('show')
        this.refs.illuThree.classList.remove('show')
      } else if (secondStepTop <= 0 && thirdStepTop > 0) {
        this.refs.illuTwo.classList.remove('show')
        this.refs.illuThree.classList.add('show')
        this.refs.illuFour.classList.remove('show')
      } else if (thirdStepTop <= 0) {
        this.refs.illuThree.classList.remove('show')
        this.refs.illuFour.classList.add('show')
      } else {
        this.refs.illuTwo.classList.remove('show')
        this.refs.illuOne.classList.add('show')
      }

      if (full <= 292) {
        this.refs.box.style.position = 'absolute'
        this.refs.box.style.top = 'auto'
        this.refs.box.style.bottom = '0'
      } else if (sliderTop <= 28) {
        this.refs.box.style.position = 'fixed'
        this.refs.box.style.top = '88px'
        this.refs.box.style.bottom = 'auto'
      } else {
        this.refs.box.style.position = 'absolute'
        this.refs.box.style.top = '60px'
        this.refs.box.style.bottom = 'auto'
      }
    }
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="salaris">
          <Helmet>
            <title>
              Salarisadministratie | Salarisverwerking | Loonadministratie
              |Employes
            </title>
            <meta
              name="description"
              content="Salarisadministratie regel je online makkelijk zelf! De salarisverwerking en loonadministratie van Employes is speciaal voor het MKB."
            />
            <meta
              itemprop="name"
              content="Salarisadministratie | Salarisverwerking | Loonadministratie |Employes"
            />
            <meta
              itemprop="description"
              content="Salarisadministratie regel je online makkelijk zelf! De salarisverwerking en loonadministratie van Employes is speciaal voor het MKB."
            />
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="header padding-xxl animated fadeInPage">
            <div className="container-md">
              <div className="grid yg center text-center">
                <div className="col-12">
                  <h1>Je salarisadministratie helemaal in eigen hand</h1>
                  <p
                    className="streamer large center margin-l-bottom"
                    style={{ maxWidth: 540 }}
                  >
                    Met Employes is salarisverwerking zo gepiept. Onze online
                    oplossing is eenvoudig te begrijpen, waardoor je het
                    makkelijk en snel kunt regelen. Bekijk hoe het werkt.
                  </p>
                  <a
                    className="btn primary lg margin-l-bottom"
                    href={appUrl + signupRoute}
                    rel="nofollow"
                  >
                    Probeer nu gratis
                  </a>
                </div>

                <div className="col-12 no-pad relative">
                  <div className="screenshot">
                    {/*<img src={screenshot} alt="Illustration" ref="screenshot" />*/}
                    <video loop autoPlay muted playsInline ref="videoContainer">
                      <source
                        src={withPrefix('/employes-explain.mp4')}
                        type="video/mp4"
                      />
                    </video>
                  </div>

                  <div className="confetti">
                    <img
                      src={confetti}
                      alt="The confetti with different faces"
                    />
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="slider above" ref="slider">
            <div className="backdrop"></div>
            <div className="slider-container">
              <div className="feature-content step" id="step-1" ref="stepOne">
                <div className="container-md padding-xxxl">
                  <div className="grid yg">
                    <div className="col-6 feature-illustration">
                      <div>
                        <img src={one} alt="Illustration" ref="illuOne" />
                      </div>
                    </div>
                    <div className="col-6 right">
                      <p className="eyebrow">Onboarding - in een paar klikken geregeld</p>
                      <h2>Snel en eenvoudig werknemers aannemen</h2>
                      {/*<p className="margin-s-bottom">Wanneer je een nieuwe werknemer aanneemt, is het belangrijk dat je alle gegevens goed vastlegt om de loonadministratie te kunnen regelen. In Employes doorloop je heel laagdrempelig een aantal stappen om de werknemer toe te voegen. Je kunt hem ook uitnodigen om het zelf te doen. Daarna kun je een template kiezen om bijvoorbeeld een arbeidscontract of stageovereenkomst te genereren. Er zijn formats aanwezig of je kunt zelf een document uploaden. Zo heb je alle informatie op dezelfde plek en voldoe je aan de AVG.</p>*/}
                      <p className="margin-s-bottom">
                        Wanneer je een nieuwe werknemer aanneemt, is het
                        belangrijk dat je alle gegevens goed vastlegt om de
                        loonadministratie te kunnen regelen. In Employes
                        doorloop je heel laagdrempelig een aantal stappen om de
                        werknemer toe te voegen. Je kunt hem ook uitnodigen om
                        het zelf te doen.
                      </p>
                      <Link to="/product/hr/" className="link mulberry">
                        Bekijk meer handige HR-functies
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature-content step" id="step-2" ref="stepTwo">
                <div className="container-md padding-xxxl-bottom">
                  <div className="grid yg">
                    <div className="col-6 feature-illustration">
                      <div>
                        <img src={two} alt="Illustration" ref="illuTwo" />
                      </div>
                    </div>
                    <div className="col-6 right">
                      <p className="eyebrow">Verlonen - bespaar tijd</p>
                      <h2>Direct zelf online een verloning doen</h2>
                      <p className="margin-s-bottom">
                        In Employes kun je de maandelijkse salarisadministratie
                        via een paar klikken doen. Voer eerst de gegevens zoals
                        de uren, vergoedingen en wijzigingen in. Alle gegevens
                        gecontroleerd? Dan worden de loonstroken binnen enkele
                        seconden gegenereerd en staat er een duidelijk
                        financieel overzicht voor je klaar. Geen tussenkomst van
                        een accountant nodig, tenzij je dat zelf prettig vindt
                        natuurlijk. In dat geval kun je je accountant gratis
                        toegang geven tot de benodigde informatie.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature-content step" id="step-3" ref="stepThree">
                <div className="container-md padding-xxxl-bottom">
                  <div className="grid yg">
                    <div className="col-6 feature-illustration">
                      <div>
                        <img src={three} alt="Illustration" ref="illuThree" />
                      </div>
                    </div>
                    <div className="col-6 right">
                      <p className="eyebrow">Inzicht voor werknemers</p>
                      <h2>Stuur loonstroken naar de werknemers</h2>
                      <p>
                        Dankzij Employes geen complexe loonadministratie, maar
                        gewoon een duidelijk overzicht; ook voor werknemers. Zij
                        ontvangen iedere maand een email met een link naar de
                        loonstrook zodat meteen helder is wat zij uitbetaald
                        krijgen. Loonstrook kwijtgeraakt? Geen probleem. Via onze mobiele app voor werknemers kunnen zij de loonstrook alsnog
                        bekijken en downloaden.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="feature-content step" id="step-4" ref="stepFour">
                <div className="container-md padding-xxxl-bottom">
                  <div className="grid yg">
                    <div className="col-6 feature-illustration">
                      <div>
                        <img src={four} alt="Illustration" ref="illuFour" />
                      </div>
                    </div>
                    <div className="col-6 right">
                      <p className="eyebrow">Flexibele integratie</p>
                      <h2>Koppel je boekhouding met onze integraties</h2>
                      <p className="margin-s-bottom">
                        Met behulp van onze integraties kun je Employes aan je
                        boekhoudpakket koppelen. Dit betekent dat je informatie
                        maar op één plek hoeft bij te houden. Het wordt
                        automatisch bijgewerkt in bijvoorbeeld Moneybird, Exact
                        Online of Yuki. Geen foutgevoeligheid en alle informatie
                        op een centrale plek. Zo heb je altijd actueel inzicht
                        in je kosten en is je boekhouding helemaal up-to-date.
                      </p>
                      <Link
                        to="/product/koppelingen/"
                        className="link mulberry"
                      >
                        Bekijk alle koppelingen
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="box" ref="box">
                <div className="container-md">
                  <div className="grid yg">
                    <div className="col-6">
                      <div className="image-container">
                        <img src={one} alt="Illustration" ref="illuOne" />
                        <img src={two} alt="Illustration" ref="illuTwo" />
                        <img src={three} alt="Illustration" ref="illuThree" />
                        <img src={four} alt="Illustration" ref="illuFour" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="options">
            <div className="overview">
              <div className="container-md">
                <div className="grid yg center text-center">
                  <div className="col-6">
                    <h4 className="subtitle padding-l">
                      Alles inbegrepen voor een efficiënte salarisadministratie
                    </h4>
                  </div>
                </div>
              </div>
              <div className="features-overview margin-l-bottom">
                <div className="features-row">
                  {RowOne.map((title, idx) => (
                    <FeatureCard key={idx} title={title} />
                  ))}
                </div>
                <div className="features-row">
                  {RowTwo.map((title, idx) => (
                    <FeatureCard key={idx} title={title} />
                  ))}
                </div>
              </div>
              <div className="container-md margin-l-bottom">
                <div className="grid yg center text-center">
                  <div className="col-6">
                    <Link
                      className="btn tertiary lg"
                      to="/product/mogelijkheden/"
                    >
                      Bekijk alle mogelijkheden
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="features padding-xxl">
            <div className="container-md">
              <div className="grid center text-center">
                <div className="col-12">
                  <p className="eyebrow">Onderscheiden</p>
                  <h2 className="margin-xl-bottom">Voordelen van Employes</h2>
                </div>

                <div className="col-3">
                  <div className="feature-icon">
                    <img src={direct} className="direct" alt="direct" />
                  </div>
                  <h5>Geoptimaliseerd voor het MKB</h5>
                  <p>
                    Je hebt geen specifieke salariskennis nodig om Employes te
                    gebruiken. De gebruiksvriendelijke applicatie kun je online
                    gebruiken. Je wordt stap voor stap door het eenvoudige
                    proces begeleid.
                  </p>
                </div>

                <div className="col-3">
                  <div className="feature-icon">
                    <img src={hand} className="hand" alt="hand" />
                  </div>
                  <h5>Binnen 6 minuten aan de slag</h5>
                  <p>
                    Meld je online aan bij Employes en je kunt direct beginnen.
                    Gemiddeld duurt een aanmelding zo’n 6 minuten. Je kunt de
                    applicatie eerst 30 dagen vrijblijvend uitproberen voordat
                    je een pakket kiest.
                  </p>
                </div>

                <div className="col-3">
                  <div className="feature-icon">
                    <img src={kosten} className="kosten" alt="kosten" />
                  </div>
                  <h5>Geen verborgen kosten, alles inbegrepen.</h5>
                  <p>
                    Met Employes weet je precies waar je aan toe bent. Je kunt
                    kiezen voor het pakket dat het beste bij jouw situatie past.
                    Alle onderdelen van dat pakket zijn volledig bij de prijs
                    inbegrepen.
                  </p>
                </div>

                <div className="col-3">
                  <div className="feature-icon">
                    <img src={vragen} className="vragen" alt="vragen" />
                  </div>
                  <h5>Wij staan je persoonlijk te woord</h5>
                  <p>
                    Bij vragen, opmerkingen of suggesties ter verbetering: je
                    kunt ons bereiken via de online chat, telefoon of e-mail.
                    Voor ons is het belangrijk dat jij optimaal met Employes
                    kunt werken.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="motivated padding-xxl">
            <div className="container-md">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <p className="eyebrow">Meer redenen nodig?</p>
                  <h2>Het verschil met bestaande oplossingen</h2>
                  <p className="margin-s-bottom">
                    Veel salarispakketten zijn ingewikkeld, waardoor je
                    eigenlijk altijd wel een accountant nodig hebt. Als
                    ondernemer heb je doorgaans niet de salariskennis om zelf de
                    salarisverwerking te regelen. Maar met Employes heb je dat
                    gelukkig ook niet nodig.
                  </p>
                  <div className="seo-link">
                    Tags:&nbsp;
                    <Link
                      className="seo-link"
                      to="/salarisadministratie-uitbesteden/"
                    >
                      Salarisadministratie uitbesteden
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salarispakket/">
                      Salarispakket
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salaris-software/">
                      Salaris-software
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/personeelsadministratie/">
                      Personeelsadministratie
                    </Link>
                    ,&nbsp;
                    <Link
                      className="seo-link"
                      to="/salarisadministratie-zelf-doen/"
                    >
                      Salarisadministratie-zelf-doen
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salarisverwerking/">
                      Salarisverwerking
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/verloning/">
                      Verloning
                    </Link>
                    ,&nbsp;
                    <Link
                      className="seo-link"
                      to="/salarisadministratiekantoor/"
                    >
                      Salarisadministratiekantoor
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/loon-software/">
                      Loon-software
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/loonadministratie/">
                      Loonadministratie
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/loonadministrateur/">
                      Loonadministrateur
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/loonstrook-maken/">
                      Loonstrook maken
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salarisprogramma/">
                      Salarisprogramma
                    </Link>
                    ,&nbsp;
                  </div>
                </div>

                <div className="col-6">
                  <div className="plan-overview">

                    
                    {/* <div className="title">
                      <img src={employes} alt="Employes logo" />
                    </div> */}

                    <div className="plans">
                      <ul>
                        {allPlans.map((plan, idx) => (
                          <li className="plan lg" key={idx}>
                            <div className="content">
                              <p>{plan.problem}</p>
                            </div>
                            <div className="content">
                              <Checkmark small={true} />
                              <h6>{plan.solution}</h6>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="change padding-xxl" ref="steps">
            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <p className="eyebrow">Gratis overstapservice</p>
                  <h2>Overstappen? Wij staan voor je klaar</h2>
                  <p className="margin-m-bottom">
                    Het lijkt een heel gedoe: overstappen naar een nieuw
                    salarispakket. Het goede nieuws is dat Employes dit gratis
                    voor jou verzorgt. Jij hoeft alleen maar een account aan te
                    maken en een aantal gegevens in te voeren. Neem vervolgens
                    contact met ons op en wij regelen de rest.
                  </p>
                  <Link
                    className="btn secondary"
                    to="/overstapservice/"
                  >
                    Hoe werkt overstappen
                  </Link>
                </div>

                <Steps />
              </div>
            </div>
          </section>

          <ClientCardContainer stories={otherClientStories} />
        </main>
      </Layout>
    )
  }
}

export default SalarisAdmin
